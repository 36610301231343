<template>
  <div class="withdraw">
    <!-- TODO 导航栏 -->
    <van-nav-bar
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <i
        slot="left"
        class="iconfont icon-jiantou1"
        @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"
      ></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'"
          >Rút tiền</span
        >
      </template>
      <template #right>
        <button
          :class="{ 'yellow-color': !$isLinkGame, 'black-bc': !$isLinkGame }"
          @click="$router.push('/user/withdraw/record')"
        >
          Lịch sử
        </button>
      </template>
    </van-nav-bar>

    <van-cell>
      <div slot="title" class="title">
        <!-- <span class="item-title">Thẻ ngân hàng của bạn</span> -->
        <span class="item-title">Liên kết thông tin ngân hàng</span>
        <span class="item-right" v-if="bankInfo">
          <span class="item-value">{{ bankInfo.bank }}</span>
          <!-- language-ch 尾号 -->
          <span class="item-value-bank"
            >Số đuôi thẻ：{{ bankInfo.card }} <br />
            {{ bankInfo.name }}
          </span>
        </span>
        <span v-else>đọc hiểu...</span>
      </div>
      <template #right-icon v-show="bankInfo.id">
        <van-icon name="arrow" slot="icon" @click="$router.push('/bank')" />
      </template>
    </van-cell>

    <!-- language-ch 可提现余额 -->
    <van-cell>
      <div slot="title" class="balance">
        <span class="balance-title">Số tiền có thể rút</span>
        <span class="balance-right red">{{ money_can }}</span>
      </div>
      <!-- language-ch 总余额 -->
      <div slot="default" class="default">
        <span class="item-title">toàn bộ </span>
        <span class="item-value">{{
          userInfo.money ? userInfo.money : "₫ 0.00"
        }}</span>
      </div>
    </van-cell>
    <!-- language-ch 提现仍需消费：₫ -->
    <div class="item-need" v-if="userInfo.money_not > 0">
      rút hoặc tiếp tục tiêu thụ: ₫ {{ userInfo.money_not }}
    </div>
    <!-- language-ch 是否可以提现 -->
    <van-cell>
      <!-- <div slot="title" class="balance">
        <span class="balance-title">Bạn có thể rút tiền không?</span>
        <span class="balance-right red"
          >{{ userInfo.money_not > 0 ? 'ไม่ได้' : 'สามารถ' }}
        </span>
      </div> -->
      <!-- 手续费 -->
      <!-- <div slot="default" class="default">
        <span class="item-title">chi phí mỗi </span>
        <span class="item-value red">₫ 0.00</span>
      </div> -->
    </van-cell>
    <!-- language-ch 请输入จำนวนเงินที่ถอน -->
    <van-field
      v-model="money"
      center
      clearable
      label="₫"
      type="number"
      placeholder="Vui lòng nhập số tiền rút."
    >
      <template #button>
        <button @click="allHandler">rút tất cả</button>
      </template>
    </van-field>

    <!-- language-ch 提 现 -->
    <button
      class="button"
      :class="{ 'yellow-color': !$isLinkGame, 'black-bc': !$isLinkGame }"
      @click="Withdrawal"
    >
      Xác nhận
    </button>

    <!-- 密码弹出层 -->
    <van-dialog
      v-model="isshowDialog"
      title="Password"
      show-cancel-button
      cancel-button-text="Hủy bỏ"
      confirm-button-text="xác nhận"
      confirmButtonColor="#00aa88"
      @confirm="onSubmit"
      @cancel="editValue = ''"
    >
      <van-field
        v-model="editValue"
        autofocus="true"
        type="password"
        placeholder="Xin vui lòng nhập mật khẩu"
      />
    </van-dialog>
  </div>
</template>

<script>
import { userBank, extract, userinfo } from "@/api/use.js";
export default {
  name: "",
  components: {},
  data() {
    return {
      sms: "",
      money: 0,
      bankInfo: {},
      userInfo: JSON.parse(window.localStorage.userInfo),
      isshowDialog: false,
      editValue: "",
      money_not: 0,
      isshowpassword: this.$route.query.isshowpassword
        ? this.$route.query.isshowpassword
        : false,
      paypassword: this.$route.query.paypassword
        ? this.$route.query.paypassword
        : true,
    };
  },
  mounted() {},
  created() {
    this.getBankInfo();
  },
  methods: {
    async getUserInfo() {
      const {
        data: { ret, data },
      } = await userinfo();

      if (ret === 1) {
        this.userInfo = data;
      }
    },
    async getBankInfo() {
      const { data: res } = await userBank();
      if (this.paypassword === true) {
        if (res.data.length === 0 && this.isshowpassword === false) {
          this.tips(
            "bank",
            "Thẻ ngân hàng của bạn chưa được thiết lập. Bạn có muốn thiết lập nó không?"
          );
        } else if (
          this.userInfo.setpaypass === 0 ||
          this.isshowpassword === true
        ) {
          this.tips(
            "user/pay_password",
            "Bạn chưa thiết lập thanh toán mở khóa mật khẩu. Bạn muốn vào cài đặt?"
          );
        }
      }
      if (res.data.length !== 0 && res.ret === 1) {
        this.bankInfo = res.data[0];
        this.bankInfo.card = this.bankInfo.card.substr(
          this.bankInfo.card.length - 4
        );
      }
    },
    allHandler() {
      if (this.money_can !== 0) {
        this.money = this.money_can;
      }
    },

    Withdrawal() {
      if (this.verConfig.needMoneyNot) {
        if (this.money_can === 0) {
          this.$toast("Cần tiêu thụ dòng nước");
          return;
        }
      }
      if (this.money === 0) {
        this.$toast("Vui lòng nhập số tiền");
        return;
      }
      // this.isshowDialog = true
      this.onSubmit();
    },

    // 提交提现
    async onSubmit() {
      this.isshowDialog = false;
      // if (this.editValue.trim().length !== 4) {
      //   this.$toast('กรุณากรอกmật khẩu mở khóaการชำระเงินที่ถูกต้อง!')
      // } else {
      const { data } = await extract({
        money: this.money,
        // paypass: this.editValue,
        bankid: this.bankInfo.id,
      });
      if (data.ret === 1) {
        this.$toast(data.msg);
        this.money = 0;
        this.getBankInfo();
        this.getUserInfo();
      } else {
        this.$toast(data.msg);
        this.editValue = "";
        this.getUserInfo();
      }

      // this.$router.push('/login')
      // }
    },

    tips(path, message) {
      this.$dialog
        .confirm({
          title: "Lời khuyên",
          message: message,
          confirmButtonText: "Xác nhận",
          cancelButtonText: "Hủy bỏ",
        })
        .then(() => {
          this.$router.push("/" + path);
        })
        .catch(() => {
          // on
          this.$router.push("/");
        });
    },
  },
  computed: {
    money_can() {
      return this.userInfo.money_not > 0 ? 0 : this.userInfo.money;
    },
  },
};
</script>

<style lang="less" scoped>
.withdraw {
  font-family: "PingFang-Regular";

  .van-nav-bar {
    // /deep/ .van-nav-bar__content {
    //   // background-color: #ff0000;
    // }
    // /deep/ .van-nav-bar__title {
    //   // color: #fff;
    // }
    button {
      background-color: #ff0000;
      border: none;
      color: #fff;
      font-size: 20px;
    }
    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
  }

  .van-cell {
    margin: 15px 0;
    font-size: 24px;
    color: #000;
    i {
      margin-top: 37px;
    }
    .title {
      display: flex;
      align-items: center;
      .item-right {
        width: 300px;
        display: flex;
        flex-direction: column;
        margin-left: 30px;
      }
    }

    .balance {
      width: 370px;
      .balance-title {
        width: 400px;
      }
      .balance-right {
        margin-left: 20px;
      }
    }

    .default {
      color: #000;
      .item-value {
        margin-left: 20px;
      }
    }
    .red {
      color: #ff0000;
    }
  }

  .item-need {
    color: #dc1323;
    text-align: right;
    font-size: 24px;
    margin-top: 24px;
    margin-right: 24px;
    margin-bottom: 24px;
  }

  .van-field {
    font-size: 30px;
    /deep/ .van-field__label {
      width: 30px;
    }
    button {
      border: none;
      color: #ff0000;
      background-color: #fff;
    }
  }

  .button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 680px;
    height: 86px;
    font-size: 30px;
    margin-top: 62px;
    background-color: #ff0000;
    border-radius: 10px;
    border: none;
    color: #fff;
  }

  .van-field {
    /deep/ .van-field__body {
      input {
        text-align: center;
      }
    }
  }
  .item-value-bank {
    width: 350px;
  }
}
</style>
